.footer{
	background: $black;
	padding: 30px 0;
	color: $white;
	font-size: responsive(14, 16);
	font-weight: 300;
	
	&__container{
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $mobile){
			display: grid;
			grid-template-areas: 'address address social'
		}
	}

	&__address{
		grid-area: address;

		@media (max-width: $mobile){
			display: flex;
		}

		&:before{
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			background: url($img + 'icon-location.svg') no-repeat;
			vertical-align: top;
			margin-top: 20px;

			@media (max-width: $mobile){
				margin: 0;
			}
		}

		@media (max-width: $mobile){
			margin: 20px 0;
		}

		p{
			display: inline-block;
			font-size: responsive(12, 16);
		}
	}

	&__social{
		grid-area: social;

		&__item{
			display: inline-block;
			width: responsive(24, 34);
			height: responsive(24, 34);
			vertical-align: top;
			margin-left: 14px;

			svg{
				path{
					fill: $orange;
					transition: fill 500ms ease;
				}
			}

			&:hover{
				svg{
					path{
						fill: $white;
						transition: fill 500ms ease;
					}
				}
			}
		}
	}

	&__copyright{
		font-size: responsive(9, 16);

		@media (max-width: $mobile){
			display: none;
		}

		&--mobile{
			display: none;
			
			@media (max-width: $mobile){
				display: block;
				text-align: center;
			}
		}
	}
}