.contact{
	background: $gray;

	.container{
		background: url($img + 'telefone2.png') no-repeat;
		background-size: contain;
		background-position: left bottom;
		position: relative;
		overflow: hidden;

		@media (max-width: $small){
			background: url($img + 'telefone-mobile.png') no-repeat;
			background-size: 100%;
			background-position: center;
		}
	}
	
	&__container{
		position: relative;
		padding: responsive(32, 128) 0;
		display: flex;
		justify-content: flex-end;
		max-width: responsive(1062, 1163);
		margin: 0 auto;
		z-index: $ix-low;
		
		@media (max-width: $mobile){
			display: block;
		}
	}

	&__head{
		@media (min-width: $mobile){
			margin-right: responsive(1, 74);
		}
	}

	&__title{
		color: $black;
		font-size: responsive(48, 72);
		font-weight: 700;
	}

	&__description{
		color: $white;
		text-transform: uppercase;
		font-size: responsive(12, 14);
		font-weight: 600;
	}

	.wpcf7{
		width: 100%;
		max-width: responsive(250, 638);
		padding-top: responsive(30, 52);
		
		@extend %font;

		@media (max-width: $mobile){
			width: 100%;
			max-width: 100%;
		}

		p{
			text-align: right;
		}

		br{
			display: none;
		}

		input,
		textarea{
			@extend %font;
			
			&:focus,
			&:active{
				outline: none;
			}
		}

		input:not([type="submit"]),
		textarea{
			border: 1px solid #a8a7a7;
			background: rgba(255, 255, 255, 0.55); 
			color: $gray-dark;
			font-weight: 700;
			font-size: 16px;
			width: 100%;

			&::placeholder{
				color: $white;
			}
		}

		input:not([type="submit"]){
			height: responsive(50, 56);
			line-height: responsive(50, 56);
			padding: 0 responsive(8, 12);
		}

		textarea{
			padding: 16px 12px;
			height: 192px;
		}

		input[type="submit"]{
			width: 152px;
			height: 36px;
			border-radius: 9px;
			background: #404040;
			color: $white;
			font-size: 16px;
			text-align: center;
			font-weight: 300;
			border: 0;
			cursor: pointer;
			transition: box-shadow 300ms ease-in-out;
			box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0);

			&:hover{
				box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
				transition: box-shadow 300ms ease-in-out;
			}
		}

		&-form-control-wrap{
			display: block;
			margin-bottom: responsive(12, 26);
		}

		&-not-valid-tip{
			font-size: 14px;
			padding-top: 12px;
		}

		.ajax-loader{
			position: absolute;
		}

		.wpcf7-response-output{
			position: fixed;
			width: 40%;
			bottom: 100px;
			left: 50%;
			transform: translateX(-50%);
			padding: 20px;
			text-align: center;
			animation: slideDown 1500ms linear 2000ms;
			animation-fill-mode: forwards;

			&.wpcf7-validation-errors{
				background: #f7e700;
			}

			&.wpcf7-mail-sent-ng{
				background: #ff0000;
			}

			&.wpcf7-mail-sent-ok{
				background:  #398f14;
			}
		}
	}
}