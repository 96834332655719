.modal{
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: $ix-highest;
	display: flex;
	align-items: center;
	justify-content: center;

	display: none;

	animation-fill-mode: forwards;

	.video-is-opened &{
		display: flex;
		animation: fadeIn 300ms ease;
	}

	.video-exit &{
		display: flex;
		opacity: 0;
		transition: opacity 500ms ease;
	}

	&__content{
		position: relative;
		max-width: responsive(768, 1062);
		width: 100%;
		margin: 0 auto;

		video{
			width: 100%;
			height: auto;
			margin: 0 auto;
		}
	}

	&__close{
		position: absolute;
		background: url($img + 'icon-close.svg') no-repeat;
		background-size: cover;
		height: 20px;
		width: 20px;
		top: -30px;
		right: 10px;
	}
}