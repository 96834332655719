.what{
	overflow: hidden;
	background: url($img + 'background-what.png');

	.container{
		position: relative;
	}

	.scroll-down{
		position: absolute;
		bottom: 0;
		right: responsive(32, 67);
	}
	
	
	&__container{
		position: relative;
		padding-top: responsive(20,70);
		padding-bottom: 200px;

		&:before{
			content: "";
			display: block;
			background: url($img + 'background-what-mask.jpg') no-repeat;
			background-size: contain;
			background-position: bottom right;
			height: 85%;
			width: 100%;
			right: -190px;
			bottom: 0;
	
			// 2494 × 662
			position: absolute;
			z-index: $ix-neutral;
	
			@media (max-width: $mobile){
				width: 250px;
				left: auto;
				right: -50px;
			}

			@media (min-width: 768px) and (max-width: $mobile){
				width: 40%;
			}
		}
	}

	&__title{
		margin-bottom: responsive(18, 30);
		font-size: responsive(28, 60);
	}

	&__description{
		position: relative;
		font-size: responsive(17, 24);
		color: $dark;
		font-weight: 300;
		margin-bottom: responsive(36, 100);
		z-index: $ix-low;
		max-width: 998px;
	}

	&__subtitle{
		position: relative;
		font-size: responsive(18, 30);
		font-weight: 600;
		font-style: italic;
		color: $orange;
		z-index: $ix-low;

		@media (max-width: $mobile){
			max-width: 80%;
		}
	}
}