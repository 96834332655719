$img: '../assets/images/';

%font{
  font-family: 'Montserrat', sans-serif;
}

$white: #ffffff;
$black: #000000;
$dark: #474747;
$light: #e1e1e0;
$gray: #c3c2c0;
$gray-dark: #797979;
// $brown: #ce7e61; // Subtitle -- Sobre Nós
// $orage-2: #de6c4e; // Subtile -- O que fazemos
// $orange-3: #d96442; // Subtitle -- Como fazemos
// $orange-4: #e6714e; // Cards -- Como fazemos
$orange: #ec764f; 
$orange-overlay: rgba(197, 102, 75, 0.64); // Header

$mobile: 1024px;
$minMobile: 1025px;
$tablet: 1025px;
$small: 540px;

$ix-lower: -1;
$ix-neutral: 0;
$ix-low: 3;
$ix-hight: 998;
$ix-highest: 1000;

@function responsive($value1, $value2, $width1: 320, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slideDown {
	from{ bottom: 50px }
	to {
		bottom: -100%
	}
}