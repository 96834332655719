.manifest{
	position: relative;
	height: responsive(430, 860);
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	// background-image: url($img + 'background-overlay.png');
	background-color: $dark;
	scroll-behavior: smooth;

	.container{
		height: 100%;
	}

	&__banner{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-size: cover;
		background-repeat: no-repeat;
		// z-index: 0;
		opacity: 0.63;
		background-attachment: fixed;

		@media (max-width: $mobile){
			opacity: 0.51;
		}
	}

	&__container{
		height: 100%;

		&:before{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-image: url($img + 'background-overlay.png');
		background-size: auto;
		background-repeat: round;
		z-index: $ix-low;
	}
	}

	&__content{
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		z-index: $ix-low;
	}

	&__logo{
		background: url($img + 'icon-logo.svg');
		width: responsive(70, 50);
		height: responsive(70, 50);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		margin-bottom: 32px;
	}

	&__title{
		color: $white;
		font-size: responsive(18, 60);
		font-weight: 600;
		margin-bottom: 20px;
	}

	&__description{
		color: $black;
		font-weight: 300;
		text-transform: uppercase;
		font-size: 16px;
		margin-bottom: 16px;
	}
}