.about{
	padding: 54px 0;
	
	&__description{
		font-size: responsive(28, 24);
		color: $orange;
		font-style: italic;
		max-width: 700px;

		&--desktop{
			@media (max-width: $mobile){
				display: none;
			}
		}

		&--mobile{
			@media (min-width: $minMobile){
				display: none;
			}

			margin-bottom: 47px;
		}

		span{
			@media (max-width: $mobile){
				display: block;

				&:first-child{
					margin-bottom: 40px;
				}

				&:last-child{
					text-align: center;
				}
			}
		}
	}

	&__title{
		text-align: right;

		@media (max-width: $mobile){
			margin-bottom: 28px;
			text-align: left;
		}
	}

	&__time{
		.time{

			&__item{
				display: flex;
				justify-content: flex-start;
				position: relative;

				@media (max-width: $mobile){
					flex-direction: column;
					margin-bottom: 30px;
				}

				@media (min-width: $minMobile){
					align-items: center;
				}

				&:nth-child(even){

					@media (min-width: $minMobile){
						flex-direction: row-reverse;
						text-align: right;
					}

					.time__item__description{
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						
						@media (max-width: $mobile){
							align-items: initial;
						}
					}

					.time__item__avatar__mask--mobile{
						transform: none;
						right: 70%;
					}

					.time__item__avatar{

						@media (max-width: $mobile){
							align-self: flex-end;
						}

						&:before{
							transform: rotate(180deg);
						}
						

						.time__item__avatar__mask{
							left: -55%;
							transform: none;
						}

						.time__item__avatar__figure{
							background-position: left;
							border-left: 2px solid $white;
							border-right: none;
						}
					}
				}

				&:nth-child(3n){
					justify-content: center;
					margin-top: 50px;
				}
				
				&__avatar{
					position: relative;
					width: 180px;
					height: responsive(190, 195);
					overflow: hidden;
					border-right: none;

					&:before{
						content: "";
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						background: url($img + 'mask-avatar-a.svg');
						background-position: center right;
						background-size: auto 101%;
						background-repeat: no-repeat;
						width: 100%;
						height: 100%;
						z-index: $ix-low;
					}

					@media (max-width: $mobile){
						margin-bottom: 32px;
					}


					&__figure{
						position: relative;
						width: 100%;
						height: 100%;
						background-position: right;
						background-size: auto 100%;
						border-right: 2px solid $white;
						max-height: 194px;
					}

					&__mask{
						background: url($img + 'mask-avatar.svg') no-repeat;
						background-size: cover;
						height: 100%;
						width: 100%;
						position: absolute;
						top: 0;
						left: 45%;
						transform: rotate(180deg);
						display: none;

						&--mobile{
							@media (min-width: $small){
								display: none;
							}

							width: 136px;
							height: 194px;
							transform: rotate(180deg);
							position: absolute;
							right: -64px;
							top: 95px;
							z-index: $ix-low;

							svg{
								opacity: 0.11;
								height: 100%;
							}

							path,rect{
								fill: $dark;
							}
						}
					}
				}

				&__description{
					width: 100%;
					max-width: 556px;
					font-size: 14px;
					font-weight: 300;
					color: $dark;
				}

				&__title{
					font-size: 14px;
					color: $dark;
					text-transform: uppercase;
					font-weight: 700;
				}
			}

		}
	}
}