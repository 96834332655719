.cards{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: responsive(0, 90);
	grid-row-gap: responsive(45, 55);
	width: 100%;

	@media (max-width: $tablet){
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: $mobile){
		grid-template-columns: repeat(1, 1fr);
	}

	&__item{
		position: relative;
		height: 340px;
		cursor: pointer;
		background-color: transparent;
		perspective: 1500px;

		@media (max-width: $mobile){
			height: responsive(350, 340);
		}

		&[data-flip="false"]{
			z-index: $ix-low;
			
			// &:hover{
			// 	.cards__inner{
			// 		transform: rotateY(30deg);
			// 	}
			// }
		}

		&[data-flip="true"]{
			z-index: initial !important;
		}

	}
	
	&__inner{
		position: relative;
		transition: transform 0.6s;
		transform-style: preserve-3d;
		height: 100%;
		width: 100%;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

		[data-flip="true"] &{
			transform: rotateY(180deg);
		}
	}

	&__front,
	&__back{
		position: absolute;
		height: 100%;
		width: 100%;
		backface-visibility: hidden;
		overflow: hidden;
	}

	&__front{
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: $ix-neutral;

		// [data-flip="true"] &{
		// 	opacity: 0;
		// }

		&__title{
			display: block;
			font-weight: 600;
			color: $white;
			text-transform: uppercase;
			font-size: responsive(22, 36);
			text-align: center;
		}
	}

	&__back{
		// display: none;
		background: $orange;
		opacity: 1;
		transform: rotateY(180deg);

		// [data-flip="true"] &{
		// 	opacity: 1;
		// }

		&__content{
			padding: responsive(20, 40) responsive(12, 30);
			height: 100%;
			overflow: hidden;
			overflow-y: scroll;
		}

		&__title{
			font-size: responsive(22, 36);
			color: $black;
			text-transform: uppercase;
			font-weight: 600;
		}

		&__description{
			display: block;
			padding: responsive(12, 20) 0 0 responsive(0, 30);
			color: $white;
			font-size: responsive(14, 16);
			font-weight: 300;
		}
	}
}