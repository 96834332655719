$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick.scss";


.hero{
	position: relative;

	&__slide{
		position: relative;
		display: block;
		height: responsive(452, 1200);
		max-height: 100vh;

	}
	
	&__image{
		height: responsive(452, 1200);
		min-height: 100%;
		background-size: cover;
		background-position: center;		
		position: relative;
		max-height: 100vh;

		@media (min-width: $small){
			background-attachment: fixed;
		}
		
		&:after{
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			background: rgba(0,0,0,0.2);
			z-index: $ix-neutral;
		}
		
		img{
			width: 100%;
			height: auto;
		}
	}

	&__content{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		width: 100%;
		z-index: $ix-low;

		@media (max-width: $mobile){
			text-align: left;
			padding: 0 32px;
		}
	}

	&__title{
		color: $white;
		font-size: responsive(30, 67);
		font-weight: 600;
		
		@media (max-width: $mobile){
			margin-bottom: 16px;
		}
	}

	&__description{
		display: block;
		color: $white;
		max-width: 418px;
		text-transform: uppercase;
		font-size: responsive(16, 15.08);
		text-shadow: -1px 1px 2px rgba(0,0,0,0.5);
		
		@media (min-width: $mobile){
			margin: 10px auto 0;
		}
	}

	&__button{
		margin-top: 20px;
	}

	&__container{
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-content: flex-end;
		z-index: $ix-neutral;
		pointer-events: none;

		button{
			pointer-events: all;
		}
	}
}