.contact .wpcf7, .contact .wpcf7 input,
.contact .wpcf7 textarea {
  font-family: 'Montserrat', sans-serif; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes slideDown {
  from {
    bottom: 50px; }
  to {
    bottom: -100%; } }

* {
  box-sizing: border-box; }

body {
  margin: 0;
  background: #ffffff;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth; }

button {
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
  cursor: pointer; }

a {
  display: block;
  text-decoration: none;
  color: inherit; }

p {
  margin: 0; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

figure {
  margin: 0; }

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

.container {
  padding: 0 calc(2.1875vw + 25px);
  overflow: hidden; }
  .container__content {
    max-width: calc(25.25vw + 687.2px);
    margin: 0 auto; }

.title {
  color: #474747;
  font-size: calc(1.875vw + 24px);
  font-weight: 900;
  text-transform: uppercase; }
  .title__light {
    font-weight: 200; }
  .title__regular {
    font-weight: 600; }
  .title__italic {
    font-weight: 200;
    font-style: italic; }

@media (max-width: 1024px) {
  body {
    padding-top: calc(2.75vw + 77.2px); } }

.header {
  background: rgba(197, 102, 75, 0.64);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000; }
  @media (max-width: 1024px) {
    .header {
      background: #ec764f; } }
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: calc(2.75vw + 77.2px); }
  .header__logo .logo__icon {
    position: absolute;
    top: 0;
    right: 2px;
    width: 29px;
    height: 99%;
    opacity: 0;
    transition: transform 550ms ease;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform-origin: center;
    transition: transform 550ms ease, opacity 200ms ease; }
    .scrolldown .header__logo .logo__icon {
      opacity: 1;
      transform: rotate(180deg);
      animation: fadeOut 200ms ease 600ms;
      animation-fill-mode: forwards;
      transition: transform 550ms ease, opacity 200ms ease; }
    .scrollup .header__logo .logo__icon {
      transform: rotate(0deg);
      transition: transform 550ms ease, opacity 200ms ease;
      animation-fill-mode: backwards; }
  .header__logo .logo figure {
    position: relative;
    height: 32px;
    width: 144px;
    transition: width 600ms linear; }
    .scrolldown .header__logo .logo figure {
      width: 29px;
      overflow: hidden;
      transition: width 600ms linear; }
    .scrollup .header__logo .logo figure {
      width: 144px;
      transition: width 600ms linear; }
    .header__logo .logo figure svg {
      height: 100%; }
  .header__logo .logo svg path {
    fill: #ffffff; }
  .scrollup.scrolldown .header__logo .logo svg .logo-o,
  .scrollup.scrolldown .header__logo .logo svg .logo-n,
  .scrollup.scrolldown .header__logo .logo svg .logo-e,
  .scrollup.scrolldown .header__logo .logo svg .logo-k-contra {
    opacity: 1;
    animation-fill-mode: backwards; }
  .scrolldown .header__logo .logo svg .logo-o,
  .scrolldown .header__logo .logo svg .logo-n,
  .scrolldown .header__logo .logo svg .logo-e,
  .scrolldown .header__logo .logo svg .logo-k-contra {
    animation: fadeOut 550ms ease;
    animation-fill-mode: forwards; }

@keyframes makeIconLogo {
  from { } }

@media (max-width: 1024px) {
  .menu {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    z-index: -1;
    transition: transform 500ms linear;
    background: #ec764f;
    padding: calc(4.625vw + 41.2px) calc(6.125vw + 10.4px);
    transform: translateX(100%); } }

.menu-is-opened .menu {
  transform: translateX(0); }

.menu__btn {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .menu__btn.active {
    transform: rotate(45deg); }
  @media (min-width: 1024px) {
    .menu__btn {
      display: none; } }

.menu__hamburguer {
  height: 3px;
  background: #ffffff;
  top: 6.5px;
  width: 100%;
  transform: translateY(-50%);
  position: absolute; }
  .menu__hamburguer:before, .menu__hamburguer:after {
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    background: #ffffff;
    position: absolute; }
  .menu__hamburguer:before {
    top: -6px;
    transition: top 75ms ease .12s,opacity 75ms ease; }
    .active .menu__hamburguer:before {
      top: 0;
      opacity: 0; }
  .menu__hamburguer:after {
    top: 6px;
    transition: top 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .active .menu__hamburguer:after {
      top: auto;
      bottom: 0;
      transform: rotate(-90deg); }

.menu__item {
  display: inline-block;
  font-size: calc(-0.125vw + 14.4px);
  text-transform: uppercase;
  margin-left: calc(1.3125vw + 9.8px);
  font-weight: 600; }
  @media (max-width: 1024px) {
    .menu__item {
      display: block;
      margin-left: 0;
      margin-bottom: 16px; } }
  .menu__item a {
    color: #ffffff;
    transition: color 500ms ease; }
    .menu__item a:hover {
      color: #000000;
      transition: color 350ms ease; }
    .menu__item a.active {
      color: #000000;
      transition: color 350ms ease; }

.button {
  font-size: calc(0.0675vw + 13.784px);
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  cursor: pointer; }
  .button__primary:before {
    content: "";
    display: inline-block;
    width: calc(0.125vw + 11.6px);
    height: calc(0.125vw + 11.6px);
    background-image: url("../assets/images/arrow-btn-orange.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: cover;
    margin-right: calc(0.125vw + 7.6px); }
  .button__primary:after {
    content: "";
    display: block;
    width: 100%;
    max-width: 0;
    height: 2px;
    background-color: #000000;
    margin: 4px auto;
    transition: max-width 300ms ease; }
  .button__primary:hover:after {
    max-width: 100%; }
  .button__secondary {
    width: calc(2.75vw + 147.2px);
    height: calc(0.5vw + 32.4px);
    background: #ffffff;
    border-radius: 24px;
    line-height: calc(0.5vw + 32.4px);
    font-weight: normal;
    transition: box-shadow 300ms ease-in-out;
    box-shadow: 2px -1px 2px 1px rgba(0, 0, 0, 0); }
    .button__secondary:before {
      content: "";
      display: inline-block;
      width: calc(0.125vw + 11.6px);
      height: calc(0.125vw + 11.6px);
      line-height: calc(0.125vw + 11.6px);
      background-image: url("../assets/images/arrow-btn-black.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: cover;
      margin-right: calc(0.125vw + 7.6px); }
    .button__secondary:hover {
      box-shadow: 2px -1px 2px 1px rgba(0, 0, 0, 0.5);
      transition: box-shadow 500ms ease-in-out; }

.slick-dots {
  position: absolute;
  font-size: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 35px; }
  .slick-dots li button {
    width: calc(0.3125vw + 9px);
    height: calc(0.3125vw + 9px);
    border-radius: 100%;
    margin: 0 10px;
    background: #ffffff;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 500ms ease-in-out; }
    .slick-dots li button:hover {
      opacity: 0.8;
      transition: opacity 350ms ease-in-out; }
  .slick-dots li.slick-active button {
    opacity: 1; }

.footer {
  background: #000000;
  padding: 30px 0;
  color: #ffffff;
  font-size: calc(0.125vw + 13.6px);
  font-weight: 300; }
  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1024px) {
      .footer__container {
        display: grid;
        grid-template-areas: 'address address social'; } }
  .footer__address {
    grid-area: address; }
    @media (max-width: 1024px) {
      .footer__address {
        display: flex; } }
    .footer__address:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/images/icon-location.svg") no-repeat;
      vertical-align: top;
      margin-top: 20px; }
      @media (max-width: 1024px) {
        .footer__address:before {
          margin: 0; } }
    @media (max-width: 1024px) {
      .footer__address {
        margin: 20px 0; } }
    .footer__address p {
      display: inline-block;
      font-size: calc(0.25vw + 11.2px); }
  .footer__social {
    grid-area: social; }
    .footer__social__item {
      display: inline-block;
      width: calc(0.625vw + 22px);
      height: calc(0.625vw + 22px);
      vertical-align: top;
      margin-left: 14px; }
      .footer__social__item svg path {
        fill: #ec764f;
        transition: fill 500ms ease; }
      .footer__social__item:hover svg path {
        fill: #ffffff;
        transition: fill 500ms ease; }
  .footer__copyright {
    font-size: calc(0.4375vw + 7.6px); }
    @media (max-width: 1024px) {
      .footer__copyright {
        display: none; } }
    .footer__copyright--mobile {
      display: none; }
      @media (max-width: 1024px) {
        .footer__copyright--mobile {
          display: block;
          text-align: center; } }

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  animation-fill-mode: forwards; }
  .video-is-opened .modal {
    display: flex;
    animation: fadeIn 300ms ease; }
  .video-exit .modal {
    display: flex;
    opacity: 0;
    transition: opacity 500ms ease; }
  .modal__content {
    position: relative;
    max-width: calc(18.375vw + 709.2px);
    width: 100%;
    margin: 0 auto; }
    .modal__content video {
      width: 100%;
      height: auto;
      margin: 0 auto; }
  .modal__close {
    position: absolute;
    background: url("../assets/images/icon-close.svg") no-repeat;
    background-size: cover;
    height: 20px;
    width: 20px;
    top: -30px;
    right: 10px; }

.scroll-down {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 calc(1.125vw + 6.4px) 16px 0; }
  .manifest .scroll-down {
    position: absolute;
    bottom: 0;
    right: calc(2.1875vw + 25px);
    z-index: 998; }
  .scroll-down__btn {
    width: calc(0.625vw + 12px);
    height: calc(0.625vw + 12px);
    transform: rotate(-90deg);
    cursor: pointer; }
    .scroll-down__btn svg {
      width: 100%;
      height: auto; }
    .scroll-down__btn--light svg path {
      fill: #ffffff; }
    .scroll-down__btn--dark svg path {
      fill: #474747; }

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: calc(5.625vw + -18px);
  grid-row-gap: calc(0.625vw + 43px);
  width: 100%; }
  @media (max-width: 1025px) {
    .cards {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 1024px) {
    .cards {
      grid-template-columns: repeat(1, 1fr); } }
  .cards__item {
    position: relative;
    height: 340px;
    cursor: pointer;
    background-color: transparent;
    perspective: 1500px; }
    @media (max-width: 1024px) {
      .cards__item {
        height: calc(-0.625vw + 352px); } }
    .cards__item[data-flip="false"] {
      z-index: 3; }
    .cards__item[data-flip="true"] {
      z-index: initial !important; }
  .cards__inner {
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    height: 100%;
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }
    [data-flip="true"] .cards__inner {
      transform: rotateY(180deg); }
  .cards__front, .cards__back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    overflow: hidden; }
  .cards__front {
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0; }
    .cards__front__title {
      display: block;
      font-weight: 600;
      color: #ffffff;
      text-transform: uppercase;
      font-size: calc(0.875vw + 19.2px);
      text-align: center; }
  .cards__back {
    background: #ec764f;
    opacity: 1;
    transform: rotateY(180deg); }
    .cards__back__content {
      padding: calc(1.25vw + 16px) calc(1.125vw + 8.4px);
      height: 100%;
      overflow: hidden;
      overflow-y: scroll; }
    .cards__back__title {
      font-size: calc(0.875vw + 19.2px);
      color: #000000;
      text-transform: uppercase;
      font-weight: 600; }
    .cards__back__description {
      display: block;
      padding: calc(0.5vw + 10.4px) 0 0 calc(1.875vw + -6px);
      color: #ffffff;
      font-size: calc(0.125vw + 13.6px);
      font-weight: 300; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.hero {
  position: relative; }
  .hero__slide {
    position: relative;
    display: block;
    height: calc(46.75vw + 302.4px);
    max-height: 100vh; }
  .hero__image {
    height: calc(46.75vw + 302.4px);
    min-height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    max-height: 100vh; }
    @media (min-width: 540px) {
      .hero__image {
        background-attachment: fixed; } }
    .hero__image:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 0; }
    .hero__image img {
      width: 100%;
      height: auto; }
  .hero__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    z-index: 3; }
    @media (max-width: 1024px) {
      .hero__content {
        text-align: left;
        padding: 0 32px; } }
  .hero__title {
    color: #ffffff;
    font-size: calc(2.3125vw + 22.6px);
    font-weight: 600; }
    @media (max-width: 1024px) {
      .hero__title {
        margin-bottom: 16px; } }
  .hero__description {
    display: block;
    color: #ffffff;
    max-width: 418px;
    text-transform: uppercase;
    font-size: calc(-0.0575vw + 16.184px);
    text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.5); }
    @media (min-width: 1024px) {
      .hero__description {
        margin: 10px auto 0; } }
  .hero__button {
    margin-top: 20px; }
  .hero__container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    z-index: 0;
    pointer-events: none; }
    .hero__container button {
      pointer-events: all; }

.about {
  padding: 54px 0; }
  .about__description {
    font-size: calc(-0.25vw + 28.8px);
    color: #ec764f;
    font-style: italic;
    max-width: 700px; }
    @media (max-width: 1024px) {
      .about__description--desktop {
        display: none; } }
    .about__description--mobile {
      margin-bottom: 47px; }
      @media (min-width: 1025px) {
        .about__description--mobile {
          display: none; } }
    @media (max-width: 1024px) {
      .about__description span {
        display: block; }
        .about__description span:first-child {
          margin-bottom: 40px; }
        .about__description span:last-child {
          text-align: center; } }
  .about__title {
    text-align: right; }
    @media (max-width: 1024px) {
      .about__title {
        margin-bottom: 28px;
        text-align: left; } }
  .about__time .time__item {
    display: flex;
    justify-content: flex-start;
    position: relative; }
    @media (max-width: 1024px) {
      .about__time .time__item {
        flex-direction: column;
        margin-bottom: 30px; } }
    @media (min-width: 1025px) {
      .about__time .time__item {
        align-items: center; } }
    @media (min-width: 1025px) {
      .about__time .time__item:nth-child(even) {
        flex-direction: row-reverse;
        text-align: right; } }
    .about__time .time__item:nth-child(even) .time__item__description {
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      @media (max-width: 1024px) {
        .about__time .time__item:nth-child(even) .time__item__description {
          align-items: initial; } }
    .about__time .time__item:nth-child(even) .time__item__avatar__mask--mobile {
      transform: none;
      right: 70%; }
    @media (max-width: 1024px) {
      .about__time .time__item:nth-child(even) .time__item__avatar {
        align-self: flex-end; } }
    .about__time .time__item:nth-child(even) .time__item__avatar:before {
      transform: rotate(180deg); }
    .about__time .time__item:nth-child(even) .time__item__avatar .time__item__avatar__mask {
      left: -55%;
      transform: none; }
    .about__time .time__item:nth-child(even) .time__item__avatar .time__item__avatar__figure {
      background-position: left;
      border-left: 2px solid #ffffff;
      border-right: none; }
    .about__time .time__item:nth-child(3n) {
      justify-content: center;
      margin-top: 50px; }
    .about__time .time__item__avatar {
      position: relative;
      width: 180px;
      height: calc(0.3125vw + 189px);
      overflow: hidden;
      border-right: none; }
      .about__time .time__item__avatar:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: url("../assets/images/mask-avatar-a.svg");
        background-position: center right;
        background-size: auto 101%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        z-index: 3; }
      @media (max-width: 1024px) {
        .about__time .time__item__avatar {
          margin-bottom: 32px; } }
      .about__time .time__item__avatar__figure {
        position: relative;
        width: 100%;
        height: 100%;
        background-position: right;
        background-size: auto 100%;
        border-right: 2px solid #ffffff;
        max-height: 194px; }
      .about__time .time__item__avatar__mask {
        background: url("../assets/images/mask-avatar.svg") no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 45%;
        transform: rotate(180deg);
        display: none; }
        .about__time .time__item__avatar__mask--mobile {
          width: 136px;
          height: 194px;
          transform: rotate(180deg);
          position: absolute;
          right: -64px;
          top: 95px;
          z-index: 3; }
          @media (min-width: 540px) {
            .about__time .time__item__avatar__mask--mobile {
              display: none; } }
          .about__time .time__item__avatar__mask--mobile svg {
            opacity: 0.11;
            height: 100%; }
          .about__time .time__item__avatar__mask--mobile path, .about__time .time__item__avatar__mask--mobile rect {
            fill: #474747; }
    .about__time .time__item__description {
      width: 100%;
      max-width: 556px;
      font-size: 14px;
      font-weight: 300;
      color: #474747; }
    .about__time .time__item__title {
      font-size: 14px;
      color: #474747;
      text-transform: uppercase;
      font-weight: 700; }

.what {
  overflow: hidden;
  background: url("../assets/images/background-what.png"); }
  .what .container {
    position: relative; }
  .what .scroll-down {
    position: absolute;
    bottom: 0;
    right: calc(2.1875vw + 25px); }
  .what__container {
    position: relative;
    padding-top: calc(3.125vw + 10px);
    padding-bottom: 200px; }
    .what__container:before {
      content: "";
      display: block;
      background: url("../assets/images/background-what-mask.jpg") no-repeat;
      background-size: contain;
      background-position: bottom right;
      height: 85%;
      width: 100%;
      right: -190px;
      bottom: 0;
      position: absolute;
      z-index: 0; }
      @media (max-width: 1024px) {
        .what__container:before {
          width: 250px;
          left: auto;
          right: -50px; } }
      @media (min-width: 768px) and (max-width: 1024px) {
        .what__container:before {
          width: 40%; } }
  .what__title {
    margin-bottom: calc(0.75vw + 15.6px);
    font-size: calc(2vw + 21.6px); }
  .what__description {
    position: relative;
    font-size: calc(0.4375vw + 15.6px);
    color: #474747;
    font-weight: 300;
    margin-bottom: calc(4vw + 23.2px);
    z-index: 3;
    max-width: 998px; }
  .what__subtitle {
    position: relative;
    font-size: calc(0.75vw + 15.6px);
    font-weight: 600;
    font-style: italic;
    color: #ec764f;
    z-index: 3; }
    @media (max-width: 1024px) {
      .what__subtitle {
        max-width: 80%; } }

.manifest {
  position: relative;
  height: calc(26.875vw + 344px);
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #474747;
  scroll-behavior: smooth; }
  .manifest .container {
    height: 100%; }
  .manifest__banner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.63;
    background-attachment: fixed; }
    @media (max-width: 1024px) {
      .manifest__banner {
        opacity: 0.51; } }
  .manifest__container {
    height: 100%; }
    .manifest__container:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url("../assets/images/background-overlay.png");
      background-size: auto;
      background-repeat: round;
      z-index: 3; }
  .manifest__content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 3; }
  .manifest__logo {
    background: url("../assets/images/icon-logo.svg");
    width: calc(-1.25vw + 74px);
    height: calc(-1.25vw + 74px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 32px; }
  .manifest__title {
    color: #ffffff;
    font-size: calc(2.625vw + 9.6px);
    font-weight: 600;
    margin-bottom: 20px; }
  .manifest__description {
    color: #000000;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 16px; }

.how {
  padding-top: calc(3.375vw + 25.2px); }
  .how .container {
    position: relative;
    overflow: visible; }
  .how__head {
    display: flex;
    align-items: baseline;
    margin-bottom: calc(3.125vw + 30px); }
    @media (max-width: 1024px) {
      .how__head {
        flex-direction: column; } }
  .how__title {
    padding-right: 18px; }
  .how__description {
    font-size: calc(0.625vw + 12px);
    color: #ec764f; }
  .how__cards {
    position: relative;
    overflow: hidden;
    padding-bottom: calc(3.125vw + 36px); }
  .how__mask {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    z-index: 0; }
    @media (max-width: 1024px) {
      .how__mask {
        top: -65px; }
        .how__mask:before {
          content: "";
          display: block;
          background: url("../assets/images/mask-konek-vertical.svg") no-repeat;
          background-size: cover;
          background-position: center center;
          height: 100%;
          width: 100%; } }
    .how__mask svg {
      height: 100%;
      width: 100%; }
      @media (max-width: 1024px) {
        .how__mask svg {
          display: none; } }
      .how__mask svg path {
        fill: rgba(255, 255, 255, 0.18); }

.contact {
  background: #c3c2c0; }
  .contact .container {
    background: url("../assets/images/telefone2.png") no-repeat;
    background-size: contain;
    background-position: left bottom;
    position: relative;
    overflow: hidden; }
    @media (max-width: 540px) {
      .contact .container {
        background: url("../assets/images/telefone-mobile.png") no-repeat;
        background-size: 100%;
        background-position: center; } }
  .contact__container {
    position: relative;
    padding: calc(6vw + 12.8px) 0;
    display: flex;
    justify-content: flex-end;
    max-width: calc(6.3125vw + 1041.8px);
    margin: 0 auto;
    z-index: 3; }
    @media (max-width: 1024px) {
      .contact__container {
        display: block; } }
  @media (min-width: 1024px) {
    .contact__head {
      margin-right: calc(4.5625vw + -13.6px); } }
  .contact__title {
    color: #000000;
    font-size: calc(1.5vw + 43.2px);
    font-weight: 700; }
  .contact__description {
    color: #ffffff;
    text-transform: uppercase;
    font-size: calc(0.125vw + 11.6px);
    font-weight: 600; }
  .contact .wpcf7 {
    width: 100%;
    max-width: calc(24.25vw + 172.4px);
    padding-top: calc(1.375vw + 25.6px); }
    @media (max-width: 1024px) {
      .contact .wpcf7 {
        width: 100%;
        max-width: 100%; } }
    .contact .wpcf7 p {
      text-align: right; }
    .contact .wpcf7 br {
      display: none; }
    .contact .wpcf7 input:focus, .contact .wpcf7 input:active,
    .contact .wpcf7 textarea:focus,
    .contact .wpcf7 textarea:active {
      outline: none; }
    .contact .wpcf7 input:not([type="submit"]),
    .contact .wpcf7 textarea {
      border: 1px solid #a8a7a7;
      background: rgba(255, 255, 255, 0.55);
      color: #797979;
      font-weight: 700;
      font-size: 16px;
      width: 100%; }
      .contact .wpcf7 input:not([type="submit"])::placeholder,
      .contact .wpcf7 textarea::placeholder {
        color: #ffffff; }
    .contact .wpcf7 input:not([type="submit"]) {
      height: calc(0.375vw + 48.8px);
      line-height: calc(0.375vw + 48.8px);
      padding: 0 calc(0.25vw + 7.2px); }
    .contact .wpcf7 textarea {
      padding: 16px 12px;
      height: 192px; }
    .contact .wpcf7 input[type="submit"] {
      width: 152px;
      height: 36px;
      border-radius: 9px;
      background: #404040;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      font-weight: 300;
      border: 0;
      cursor: pointer;
      transition: box-shadow 300ms ease-in-out;
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0); }
      .contact .wpcf7 input[type="submit"]:hover {
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
        transition: box-shadow 300ms ease-in-out; }
    .contact .wpcf7-form-control-wrap {
      display: block;
      margin-bottom: calc(0.875vw + 9.2px); }
    .contact .wpcf7-not-valid-tip {
      font-size: 14px;
      padding-top: 12px; }
    .contact .wpcf7 .ajax-loader {
      position: absolute; }
    .contact .wpcf7 .wpcf7-response-output {
      position: fixed;
      width: 40%;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      padding: 20px;
      text-align: center;
      animation: slideDown 1500ms linear 2000ms;
      animation-fill-mode: forwards; }
      .contact .wpcf7 .wpcf7-response-output.wpcf7-validation-errors {
        background: #f7e700; }
      .contact .wpcf7 .wpcf7-response-output.wpcf7-mail-sent-ng {
        background: #ff0000; }
      .contact .wpcf7 .wpcf7-response-output.wpcf7-mail-sent-ok {
        background: #398f14; }
