body{
  // spacing header on mobile
  @media (max-width: $mobile){
    padding-top: responsive(86, 130);
  }
}

.header{
  background: $orange-overlay;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $ix-highest;

  @media (max-width: $mobile){
    background: $orange;
  }
  
  &__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: responsive(86, 130);
  }

  &__logo{
    .logo{

      &__icon{
        position: absolute;
        top: 0;
        right: 2px;
        width: 29px;
        // transform: rotate(180deg);
        height: 99%;
        opacity: 0;
        transition: transform 550ms ease;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        transform-origin: center;
        transition: transform 550ms ease, opacity 200ms ease;

        .scrolldown &{
          opacity: 1;
          transform: rotate(180deg);
          animation: fadeOut 200ms ease 600ms;
          animation-fill-mode: forwards;
          transition: transform 550ms ease, opacity 200ms ease;
        }

        .scrollup &{
          // opacity: 0;
          transform: rotate(0deg);
          transition: transform 550ms ease, opacity 200ms ease;
          animation-fill-mode: backwards;
        }
      }
      
      figure{
        position: relative;
        height: 32px;
        width: 144px;
        transition: width 600ms linear;

        .scrolldown &{
          width: 29px;
          overflow: hidden;
          transition: width 600ms linear;
        }

        .scrollup &{
          width: 144px;
          transition: width 600ms linear;
        }

        svg{
          height: 100%;
        }
      }

      svg {
        path{
          fill: $white;
        }

        .scrollup.scrolldown &{
          .logo-o,
          .logo-n,
          .logo-e,
          .logo-k-contra{
            opacity: 1;
            animation-fill-mode: backwards;
          }
        }

        .scrolldown &{
          .logo-o,
          .logo-n,
          .logo-e,
          .logo-k-contra{
            animation: fadeOut 550ms ease;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}


@keyframes makeIconLogo {
  from {

  }
}