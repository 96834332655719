.slick{
	&-slider{
		// height: 55px;
	}

	&-dots{
		position: absolute;
		font-size: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		align-content: center;
		// justify-content: space-evenly;
		justify-content: center;
		height: 35px;
		// z-index: 999;
		
		li{
			
			button{
				width: responsive(10, 15);
				height: responsive(10, 15);
				border-radius: 100%;
				margin: 0 10px;
				background: $white;
				opacity: 0.5;
				cursor: pointer;
				transition: opacity 500ms ease-in-out;

				&:hover{
					opacity: 0.8;
					transition: opacity 350ms ease-in-out;
				}
			}


			&.slick-active{
				button{
					opacity: 1;
				}
			}
		}
	}
}