.scroll-down{
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	padding: 0 responsive(10, 28) 16px 0;

	.manifest &{
		position: absolute;
		bottom: 0;
		right: responsive(32, 67);
		z-index: $ix-hight;
	}

	&__btn{
		width: responsive(14, 24);
		height: responsive(14, 24);
		transform: rotate(-90deg);
		cursor: pointer;

		svg{
			width: 100%;
			height: auto;
		}

		&--light{
			svg{
				path{
					fill: $white;
				}
			}
		}

		&--dark{
			svg{
				path{
					fill: $dark;
				}
			}
		}
	}
}