.button{
	font-size: responsive(14, 15.08);
	text-transform: uppercase;
	color: $black;
	font-weight: 700;
	cursor: pointer;

	&__primary{
		&:before{
			content: "";
			display: inline-block;
			width: responsive(12, 14);
			height: responsive(12, 14);
			background-image: url($img + 'arrow-btn-orange.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: cover;
			margin-right: responsive(8, 10);
		}

		&:after{
			content: "";
			display: block;
			width: 100%;
			max-width: 0;
			height: 2px;
			background-color: $black;
			margin: 4px auto;
			transition: max-width 300ms ease;
		}

		&:hover{
			&:after{
				max-width: 100%;
			}
		}
	}

	&__secondary{
		width: responsive(156, 200);
		height: responsive(34, 42);
		background: $white;
		border-radius: 24px;
		line-height: responsive(34, 42);
		font-weight: normal;
		transition: box-shadow 300ms ease-in-out;
		box-shadow: 2px -1px 2px 1px rgba(0, 0, 0, 0);
		
		&:before{
			content: "";
			display: inline-block;
			width: responsive(12, 14);
			height: responsive(12, 14);
			line-height: responsive(12, 14);
			background-image: url($img + 'arrow-btn-black.svg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: cover;
			margin-right: responsive(8, 10);
		}

		&:hover{
			box-shadow: 2px -1px 2px 1px rgba(0, 0, 0, 0.5);
			transition: box-shadow 500ms ease-in-out;
		}
	}
}