.menu{
	@media (max-width: $mobile){
		position: fixed;
		height: 100vh;
		width: 100%;
		left: 0;
		z-index: $ix-lower;
		transition: transform 500ms linear;
		background: $orange;
		padding: responsive(56, 130) responsive(30, 128);
		transform: translateX(100%);
	}

	.menu-is-opened &{
		transform: translateX(0);
	}

	&__btn{
		position: relative;
      width: 16px;
      height: 16px;
		cursor: pointer;
		transition: transform 75ms cubic-bezier(.215,.61,.355,1);

		&.active{
			transform: rotate(45deg);
		}

		@media (min-width: $mobile){
			display: none;
		}
	}

	&__hamburguer{
		height: 3px;
		background: $white;
		top: 6.5px;
		width: 100%;
		transform: translateY(-50%);
		position: absolute;

		
		&:before,
		&:after{
			content: "";
			display: block;
			height: 3px;
			width: 100%;
			background: $white;
			position: absolute;
		}

		&:before{
			top: -6px;
			transition: top 75ms ease .12s,opacity 75ms ease;

			.active &{
				top: 0;
				opacity: 0;
			}
		}

		&:after{
			top: 6px;
			transition: top 75ms ease .12s, transform 75ms cubic-bezier(.55,.055,.675,.19);

			.active &{
				top: auto;
				bottom: 0;
				transform: rotate(-90deg);
			}
		}
	}

	&__item{
		display: inline-block;
		font-size: responsive(14, 12);
		text-transform: uppercase;
		margin-left: responsive(14, 35);
		font-weight: 600;
		
		@media (max-width: $mobile){
			display: block;
			margin-left: 0;
			margin-bottom: 16px;
		}
		
		a{
			color: $white;
			transition: color 500ms ease;

			&:hover{
				color: $black;
				transition: color 350ms ease;
			}

			&.active{
				color: $black;
				transition: color 350ms ease;
			}
		}
	}
}