.title{
	color: $dark;
	font-size: responsive(30, 60);
	font-weight: 900;
	text-transform: uppercase;

	&__light{
		font-weight: 200;
	}

	&__regular{
		font-weight: 600;
	}

	&__italic{
		font-weight: 200;
		font-style: italic;
	}
}