*{
  box-sizing: border-box;
}


body {
  margin: 0;
  background: $white;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

button {
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
  cursor: pointer;
}

a{
  display: block;
  text-decoration: none;
  color: inherit;
}

p{
  margin: 0;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

figure{
  margin: 0;
}

h1,
h2,
h3,
h4{
  margin: 0;
  padding: 0;
  font-weight: normal;
}
