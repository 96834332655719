.how{
	padding-top: responsive(36, 90);

	.container{
		position: relative;
		overflow: visible;
	}

	&__head{
		display: flex;
		align-items: baseline;
		margin-bottom: responsive(40, 90);

		@media (max-width: $mobile){
			flex-direction: column;
		}
	}

	&__title{
		padding-right: 18px;
	}

	&__description{
		font-size: responsive(14, 24);
		color: $orange;
	}

	&__cards{
		position: relative;
		overflow: hidden;
		padding-bottom: responsive(46, 96);
	}

	&__mask{
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		pointer-events: none;
		z-index: $ix-neutral;

		@media (max-width: $mobile){
			top: -65px;

			&:before{
				content: "";
				display: block;
				background: url($img + 'mask-konek-vertical.svg') no-repeat;
				background-size: cover;
				background-position: center center;
				height: 100%;
				width: 100%;
			}
		}


		svg{
			height: 100%;
			width: 100%;

			@media (max-width: $mobile){
				display: none;
			}

			path{
				fill: rgba(255, 255, 255, 0.18);
			}
		}
	}
}